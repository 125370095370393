<template>
  <div class="HelpDnsView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>
          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.dns1.subtitle"/>
            <TextBlockDefault :textContent="messages.dns1.content1"/>
            <TextBlockDefault :textContent="messages.dns1.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.dns2.subtitle"/>
            <TextBlockDefault :textContent="messages.dns2.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.dns3.subtitle"/>
            <TextBlockDefault :textContent="messages.dns3.content1"/>
            <TextBlockDefault :textContent="messages.dns3.content2"/>
            <TextBlockDefault :textContent="messages.dns3.content3"/>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'HelpDnsView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      messages: {
        title: this.$t('helpView.subtitle4'),
        dns1: {
          subtitle: this.$t('helpView.dns.dns1.subtitle'),
          content1: this.$t('helpView.dns.dns1.content1'),
          content2: this.$t('helpView.dns.dns1.content2'),
        },
        dns2: {
          subtitle: this.$t('helpView.dns.dns2.subtitle'),
          content1: this.$t('helpView.dns.dns2.content1'),
        },
        dns3: {
          subtitle: this.$t('helpView.dns.dns3.subtitle'),
          content1: this.$t('helpView.dns.dns3.content1'),
          content2: this.$t('helpView.dns.dns3.content2'),
          content3: this.$t('helpView.dns.dns3.content3'),
        },
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.HelpDnsView {

  .md-list {
    margin: 1.6rem .8rem;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }
}
</style>
