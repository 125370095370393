<template>
  <div class="HelpPaymentsView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>
          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.payments.subtitle"/>
            <TextBlockDefault :textContent="messages.payments.content1"/>
            <md-list>
              <md-list-item>{{ messages.payments.listItem1 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem2 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem3 }}</md-list-item>
            </md-list>
            <TextBlockDefault :textContent="messages.payments.content2"/>
            <TextBlockDefault :textContent="messages.payments.content3"/>
            <TextBlockDefault :textContent="messages.payments.content4"/>
            <TextBlockDefault :textContent="messages.payments.content5"/>
            <TextBlockDefault :textContent="messages.payments.content6"/>
            <md-list>
              <md-list-item>{{ messages.payments.listItem4 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem5 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem6 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem7 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem8 }}</md-list-item>
              <md-list-item>{{ messages.payments.listItem9 }}</md-list-item>
            </md-list>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'HelpPaymentsView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      messages: {
        title: this.$t('helpView.subtitle2'),
        payments: {
          subtitle: this.$t('helpView.payments.subtitle'),
          content1: this.$t('helpView.payments.content1'),
          listItem1: this.$t('helpView.payments.listItem1'),
          listItem2: this.$t('helpView.payments.listItem2'),
          listItem3: this.$t('helpView.payments.listItem3'),
          content2: this.$t('helpView.payments.content2'),
          content3: this.$t('helpView.payments.content3'),
          content4: this.$t('helpView.payments.content4'),
          content5: this.$t('helpView.payments.content5'),
          content6: this.$t('helpView.payments.content6'),
          listItem4: this.$t('helpView.payments.listItem4'),
          listItem5: this.$t('helpView.payments.listItem5'),
          listItem6: this.$t('helpView.payments.listItem6'),
          listItem7: this.$t('helpView.payments.listItem7'),
          listItem8: this.$t('helpView.payments.listItem8'),
          listItem9: this.$t('helpView.payments.listItem9'),
        },
      }
    };
  },
};
</script>

<style lang="scss">
.HelpPaymentsView {

  .md-list {
    margin: 0 .8rem;
    padding-top: 0;
  }

  .md-list-item-content {
    min-height: 2.8rem;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }
}
</style>
